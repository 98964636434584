// eslint-disable react/no-danger
/** @jsx jsx */
import { Container, Heading, jsx, Box, Flex, Divider, Text } from "theme-ui";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Moment from "react-moment";
import { graphql } from "gatsby";
import MainLayout from "./MainLayout";
import Header from "../components/Header";
import StyledLink from "../components/Link.styled";

const BlogPostLayout = ({
  data: {
    wpPost: {
      categories,
      content,
      customFields: { subtitle },
      date,
      excerpt,
      featuredImage,
      title,
    },
  },
  location: { pathname },
  pageContext: { next, prev },
}) => (
  <MainLayout
    description={excerpt.replace(/(<([^>]+)>)/gi, "")}
    pathname={pathname}
    title={title}
  >
    <Header
      pathname={pathname}
      sx={{ background: "linear-gradient(to right top, #07112b, #142b53)" }}
    />
    <Container as="article" px="3" py="8" sx={{ maxWidth: "66ch" }}>
      <Flex sx={{ alignItems: "center", flexDirection: "column" }}>
        {categories.nodes.map((category) => (
          <StyledLink
            key={category.name}
            color="blue.mid"
            fontSize={0}
            px="2"
            py="1"
            sx={{
              backgroundColor: "blue.xlight",
              borderRadius: "full",
              lineHeight: "none",
            }}
            textDecoration="none"
            title={category.name}
            to={`/blog${category.uri}`}
          >
            {category.name}
          </StyledLink>
        ))}
        <Heading as="h1" variant="blog.post.title">
          {title}
        </Heading>
        {subtitle && (
          <Heading as="h2" mt="1" variant="blog.post.subtitle">
            {subtitle}
          </Heading>
        )}
        {date && (
          <Moment
            format="MMMM D, YYYY"
            sx={{ mt: "2", variant: "text.body.sm" }}
          >
            {date}
          </Moment>
        )}
      </Flex>
      <Flex sx={{ justifyContent: "space-between" }}>
        {prev && (
          <Box>
            <Text sx={{ color: "grey.dark" }} variant="body.sm">
              Previous Article
            </Text>
            <StyledLink
              color="blue.dark"
              fontWeight="medium"
              textDecoration="none"
              to={`/blog${prev.uri}`}
            >
              {prev.title}
            </StyledLink>
          </Box>
        )}
        {next && (
          <Box sx={{ textAlign: "right" }}>
            <Text sx={{ color: "grey.dark" }} variant="body.sm">
              Next Article
            </Text>
            <StyledLink
              color="blue.dark"
              fontWeight="medium"
              textDecoration="none"
              to={`/blog${next.uri}`}
            >
              {next.title}
            </StyledLink>
          </Box>
        )}
      </Flex>
      <Divider mb="6" mt="3" variant="divider.light" />
      {featuredImage !== null && (
        <Img
          fluid={featuredImage.node.localFile.childImageSharp.fluid}
          sx={{ mb: "3" }}
        />
      )}
      <Box
        as="section"
        dangerouslySetInnerHTML={{ __html: content }}
        sx={{
          "& > p:not(:first-of-type)": { mt: "3" },
          "& > p a": {
            fontWeight: "bold",
            textDecoration: "none",
            color: "blue.xdark",
          },
          "& > figure": {
            mt: "3",
            mr: "0",
            mb: "0",
            ml: "0",
            ".wp-block-embed__wrapper": {
              position: "relative",
              width: "full",
              pb: "56.25%",
              iframe: {
                position: "absolute",
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
                border: "0",
                width: "full",
                height: "full",
              },
            },
            figcaption: { mt: "2" },
          },
        }}
        variant="text.body.normal"
      />
    </Container>
  </MainLayout>
);

BlogPostLayout.propTypes = {
  data: PropTypes.shape({
    wpPost: PropTypes.shape({
      categories: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            uri: PropTypes.string,
          })
        ),
      }),
      content: PropTypes.string,
      customFields: PropTypes.shape({
        subtitle: PropTypes.string,
      }),
      date: PropTypes.string,
      excerpt: PropTypes.string,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          localFile: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
            }),
          }),
        }),
      }),
      title: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    next: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
    prev: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      uri: PropTypes.string,
    }),
  }),
};

BlogPostLayout.defaultProps = {
  data: {
    author: {},
    categories: {
      nodes: {
        name: null,
        uri: null,
      },
    },
  },
  location: {
    pathname: "/blog/",
  },
  pageContext: {},
};

export default BlogPostLayout;

export const BloqQuery = graphql`
  query BlogPostQuery($id: String) {
    wpPost(id: { eq: $id }) {
      categories {
        nodes {
          uri
          name
        }
      }
      customFields {
        subtitle
      }
      date
      id
      title
      content
      excerpt
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
